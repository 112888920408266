import React from "react";
import CommonPanel from "../components/CommonPanel";
import Cards from "../components/Cards";
import "../styles/advisory.css";

export default function Advisory() {
  const InternationalAdvisoryCommittee = [
    {
      name: "Prof. Kambiz Vafai",
      place: "University of California, USA",
    },
    {
      name: "Prof. Ishak Hashim",
      place: "University Kebangsaan, Malaysia",
    },
    {
      name: "Prof. Pallath Chandran",
      place: "Qaboos University, Oman",
    },
    {
      name: "Prof. I. Chung Liu",
      place: "National Chi Nan University, Taiwan",
    },
    {
      name: "Prof. B. K. Jha",
      place: "Ahumadu Bello University, Nigeria",
    },
    {
      name: "Prof. J. Prakash",
      place: "University of Botswana, South Africa",
    },
    {
      name: "Prof. Sanjay Misra",
      place: "Atlim University, Turkey",
    },
    {
      name: "Prof. Shankar P. Bhattacharya",
      place: "Texas University, Texas",
    },
    {
      name: "Prof. Rajkumar Buyya",
      place: "The University of Melbourne, Australia",
    },
    {
      name: "Prof. Goutam Chakraborty",
      place: "Iwate Prefectural University, Japan",
    },
    {
      name: "Prof. Deepti Mishra",
      place: "Monash University, Malaysia",
    },
    {
      name: "Prof. Shaher Momani",
      place: "The University of Jordan, Jordan",
    },
    {
      name: "Prof. Junesang Choi",
      place: "Dongguk University, Republic of Korea",
    },
    {
      name: "Prof. Martin Bohner",
      place: "Missouri University of S & T, USA",
    },
    {
      name: "Prof. Aydin Secer",
      place: "Yildiz Technical University, Turkey",
    },
    {
      name: "Prof. Juan J. Nieto",
      place: "University of Santiago de Compostela, Spain",
    },
    {
      name: "Prof. Valentina Emilia Balas",
      place: "Aurel Vlaicu University of Arad, Romania",
    },
    {
      name: "Prof. Carlo Cattani",
      place: "University of Tuscia, Italy",
    },
    {
      name: "Prof. Nirmal C Sacheti",
      place: "Sultan Qaboos University, Oman",
    },
    {
      name: "Prof. Sangping Ren",
      place: "Illinois Institute of Technology, Chicago",
    },
  ];
  const NationalAdvisoryCommittee = [
    {
      name: "Prof. V. D. Sharma",
      place: "IIT Mumbai, India",
    },
    {
      name: "Prof. Peeyush Chandra",
      place: "Formerly at IIT Kanpur , Kanpur        ",
    },
    {
      name: "Prof. Dinesh Singh",
      place: "University of Delhi, Delhi",
    },
    {
      name: "Prof. Satya Deo",
      place: "Harish-Chandra Research Institute, Jhunsi",
    },
    {
      name: "Prof. K. K. Raina",
      place: "M. S. Ramaiah University, Bengaluru",
    },
    {
      name: "Prof. G. P. Raja Sekhar",
      place: "IIT Kharagpur, Kharagpur",
    },
    {
      name: "Prof. P. S. Datti",
      place: "TIFR Bangalore, Bangalore",
    },
    {
      name: "Prof. P. G. Siddheshwar",
      place: "Bangalore University (Formerly), Bangalore",
    },
    {
      name: "Prof. P. N. Pandey",
      place: "University of Allahabad, Prayagraj",
    },
    {
      name: "Prof. Joydev Chattopadhyay",
      place: "Indian Statistical Institute, Kolkata, India",
    },
    {
      name: "Prof. R. C. Mittal",
      place: "Formerly at IIT Roorkee, Roorkee",
    },
    {
      name: " Prof. A. K. Mishra",
      place: "Institute of Sciences, BHU, Varanasi",
    },
    {
      name: "Prof. M. K. Singh",
      place: "IIT (ISM) Dhanbad, Dhanbad",
    },
    {
      name: "Prof. Ram Krishna Pandey",
      place: "IIT Roorkee, Roorkee",
    },
    {
      name: "Prof. Kunwar Singh",
      place: "NIT, Tiruchirappalli",
    },
    {
      name: "Prof. J.C. Prajapati",
      place: "Sardar Patel University, Gujarat",
    },
    {
      name: "Prof. A.K. Lal",
      place: "Thapar University, Punjab",
    },
    {
      name: "Prof. K. K. Shukla",
      place: "IIT-BHU, Varanasi",
    },
    {
      name: "Prof. Hemen Dutta",
      place: "Gauhati University, Guwahati",
    },
    {
      name: "Prof. Vineet Kumar Singh",
      place: "IIT-BHU, Varanasi",
    },
    {
      name: "Prof. Nilay Khare",
      place: "MANIT, Bhopal",
    },
    {
      name: "Prof. M. Subbiah",
      place: "Pondicherry University, Pondicherry",
    },
    {
      name: "Prof. S. Saravanan",
      place: "Bharathiar University, Coimbatore",
    },
    {
      name: "Prof. Dharmendra Tripathi",
      place: "NIT, Uttarakhand",
    },
    {
      name: "Prof. Triloki Nath",
      place: "Dr. Hari Singh Gaur University, Sagar",
    },
    {
      name: "Prof. Pankaj Mishra",
      place: "Central University, Bihar",
    },
    {
      name: "Prof. Ratnesh Kumar Mishra",
      place: "NIT Jamshedpur, Jharkhand",
    },
    {
      name: "Prof. C. Ravichandran",
      place: "K.A.S.C., Coimbatore, Tamil Nadu",
    },
  ];
  return (
    <main>
      <CommonPanel heading="Advisory Committee" />
      <div className="container">
        <h2>International Advisors:</h2>
        <div className="stack">
          {InternationalAdvisoryCommittee.map((item, index) => {
            return <Cards key={index} name={item.name} place={item.place} />;
          })}
        </div>
        <h2>National Advisors:</h2>
        <div className="stack">
          {NationalAdvisoryCommittee.map((item, index) => {
            return <Cards key={index} name={item.name} place={item.place} />;
          })}
        </div>
      </div>
    </main>
  );
}
