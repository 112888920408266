import React from "react";
import "../styles/impDates.css";
import ICAM from "../media/icamcs.png";

export default function ImpDates() {
  return (
    <section className="home-dates">
      <img src={ICAM} alt="?" />
      <div className="dates-Div">
        <h1>Important Dates</h1>
        <ul>
          <li className="text">
            Registration & Abstract/Paper submission opens — May 20, 2022
          </li>
          <li className="text">
            Deadline for Registration & Abstract/Paper submission — August 30,
            2022
          </li>
          <li className="text">
            Notification of Acceptance — September 20, 2022
          </li>
          <li className="text">Conference Dates — October 12 - 14, 2022</li>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdbzguvBY3YHGTWmBnNCzDDoANA0kVEt5UzgclRyJXyt45bvw/viewform"
            target="_blank"
            rel="noreferrer"
          >
            <button>Register</button>
          </a>
        </ul>
      </div>
    </section>
  );
}
