import React from "react";
import CommonPanel from "../components/CommonPanel";
import Cards from "../components/Cards";
import "../styles/advisory.css";
import KN1 from "../media/ODMakinde.jpg";
import KN2 from "../media/PGSiddheshwar.jpg";
import KN3 from "../media/Baleanu.jpg";
import S1 from "../media/MdShafiqul.jpg";
import S2 from "../media/CarloCattani.jpg";
import S3 from "../media/AliAkgul.jpg";
import S4 from "../media/AbdelhaleemAbdelaty.jpg";
import S5 from "../media/Kanchana.jpg";
import S6 from "../media/MaheshaNarayana.jpg";
import S7 from "../media/SatyaDeo.png";
import S8 from "../media/pnpadey.jpg";
import S9 from "../media/RCMittal.jpg";
import S10 from "../media/GPRajSekhar.jpg";
import S11 from "../media/MKKadalbajoo.jpg";
import S12 from "../media/Amitabha.jpg";
import S13 from "../media/SanjayKumarSingh.jpg";
import S14 from "../media/Chair_Naresh-M-Chadha.jpg";
import S15 from "../media/RajeshPandey.jpg";
import S16 from "../media/Dharmendra.jpg";
import S17 from "../media/harendra.jpg";

export default function Speakers() {
  const special = [
    {
      name: "Late Prof. Satya Deo",
      place: "Harish-Chandra Research Institute, Jhunsi",
      img: S7,
    },
  ];
  const list1 = [
    {
      name: "Prof. O. D. Makinde",
      place: "Stellenbosch University, South Africa",
      img: KN1,
    },
    {
      name: "Prof. P. G. Siddheshwar",
      place: "Formerly at Bangalore University, Bangalore",
      img: KN2,
    },
    {
      name: "Prof. D. Baleanu",
      place: "Cankaya University, Ankara, Turkey",
      img: KN3,
    },
  ];
  const speakers = [
    {
      name: "Prof. Md. Shafiqul Islam",
      place: "University of Dhaka, Bangladesh",
      img: S1,
    },
    {
      name: "Prof. Carlo Cattani",
      place: "University of Tuscia, Italy",
      img: S2,
    },
    {
      name: "Prof. Ali Akgul",
      place: "Siirt University, Yeni, Turkey",
      img: S3,
    },
    {
      name: "Prof. Abdelhaleem Abdelaty",
      place: "University of Bisha, Saudi Arabia",
      img: S4,
    },
    {
      name: "Prof. Kanchana",
      place: "University of Tarapaca, Chile",
      img: S5,
    },
    {
      name: "Prof. Mahesha Narayana",
      place: "The University of West Indies, Jamaica",
      img: S6,
    },
    {
      name: "Prof. P. N. Pandey",
      place: "University of Allahabad, Prayagraj",
      img: S8,
    },
    {
      name: "Prof. R. C. Mittal",
      place: "Formerly at IIT Roorkee, Roorkee",
      img: S9,
    },
    {
      name: "Prof. G. P. Raja Sekhar",
      place: "IIT Kharagpur, Kharagpur",
      img: S10,
    },
    {
      name: "Prof. M. K. Kadalbajoo",
      place: "Formerly at IIT Kanpur, Kanpur",
      img: S11,
    },
    {
      name: "Prof. Amitabha Bagchi",
      place: "IIT Delhi, Delhi",
      img: S12,
    },
    {
      name: "Prof. Sanjay Kumar Singh",
      place: "IIT-BHU, Varanasi",
      img: S13,
    },
    {
      name: "Prof. Naresh M. Chadha",
      place: "DIT University, Dehradun",
      img: S14,
    },
    {
      name: "Prof. Rajesh Pandey",
      place: "IIT-BHU, Varanasi",
      img: S15,
    },
    {
      name: "Prof. Dharmendra Tripathi",
      place: "NIT Uttarakhand",
      img: S16,
    },
    {
      name: "Prof. Harendra Singh",
      place: "P. G. College Ghazipur, U.P., India",
      img: S17,
    },
  ];

  return (
    <main>
      <CommonPanel heading="Speakers" />
      <div className="container">
        <div className="centerin">
          <div className="rip">
            {special.map((item, index) => {
              return (
                <Cards
                  key={index}
                  name={item.name}
                  place={item.place}
                  img={item.img}
                  subtext={
                    <>
                      The ICAMCS-2022 organizing committee pays tribute to the
                      late Professor Satya Deo. May God bless the peace to the
                      departed soul.
                      <br />
                      Mathematical contributions made by the Late Prof. Satya
                      Deo will live on in the memories.
                    </>
                  }
                />
              );
            })}
          </div>
          <h2>Keynote Speakers:</h2>
          <div className="stack">
            {list1.map((item, index) => {
              return (
                <Cards
                  key={index}
                  name={item.name}
                  place={item.place}
                  img={item.img}
                />
              );
            })}
          </div>
        </div>
        <h2>Speakers:</h2>
        <div className="stack">
          {speakers.map((item, index) => {
            return (
              <Cards
                key={index}
                name={item.name}
                place={item.place}
                img={item.img}
              />
            );
          })}
        </div>
      </div>
    </main>
  );
}
