import React from "react";
import "../styles/commonPanel.css";

export default function CommonPanel(Params) {
  return (
    <div className="commonPanel">
      <h1>{Params.heading}</h1>
    </div>
  );
}
