import React from "react";
import CommonPanel from "../components/CommonPanel";
import "../styles/footer.css";
import Conv from "../media/jd.png";
import Conv2 from "../media/sy.png";

export default function Contact() {
  return (
    <main>
      <CommonPanel heading="Contact Us" />
      <div className="footer-contacts">
        <img src={Conv} alt="Jogendra" />
        <h5>Dr. Jogendra Kumar</h5>
        <p>Convener, ICAMCS-2022</p>
        <p>Department of Mathematics</p>
        <p>
          DIT University, Dehradun-248009, India
          <br />
          Tel: +91-7906546020
        </p>
        <p>E-mails: icamcs.dituniversity@gmail.com</p>
        <br />
        <img src={Conv2} alt="Jogendra" />
        <h5>Dr. Seema Yadav</h5>
        <p>Co-Convener, ICAMCS-2022</p>
        <p>Department of Mathematics</p>
        <p>
          DIT University, Dehradun-248009, India
          <br />
          Tel: +91-8938024124
        </p>
        <p>E-mails: icamcs.dituniversity@gmail.com</p>
      </div>
    </main>
  );
}
