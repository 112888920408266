import React from "react";
import D1 from "../media/DSC1.JPG";
import D2 from "../media/DSC2.JPG";
import D3 from "../media/DSC3.JPG";
import D4 from "../media/DSC4.JPG";
import D5 from "../media/DSC5.JPG";
import D6 from "../media/DSC6.JPG";
import D7 from "../media/DSC7.JPG";
import D8 from "../media/DSC8.JPG";
import D9 from "../media/DSC9.JPG";
import D10 from "../media/DSC10.JPG";
import D11 from "../media/DSC11.JPG";
import D12 from "../media/DSC_0106.JPG";
import "../styles/gallery.css";

export default function Gallery() {
  return (
    <div>
      <h2 style={{ padding: "20px" }}>ICAMCS 2019 Glimpses</h2>
      <div className="gallery" id="data">
        <img className="gallerySub" src={D1} alt="img" />
        <img className="gallerySub" src={D12} alt="img" />
        <img className="gallerySub" src={D3} alt="img" />
        <img className="gallerySub" src={D4} alt="img" />
        <img className="gallerySub" src={D10} alt="img" />
        <img className="gallerySub" src={D2} alt="img" />
        <img className="gallerySub" src={D8} alt="img" />
        <img className="gallerySub" src={D9} alt="img" />
        <img className="gallerySub" src={D6} alt="img" />
        <img className="gallerySub" src={D7} alt="img" />
        <img className="gallerySub" src={D11} alt="img" />
        <img className="gallerySub" src={D5} alt="img" />
      </div>
    </div>
  );
}
