import React from "react";
import CommonPanel from "../components/CommonPanel";
import Cards from "../components/Cards";
import "../styles/advisory.css";
import "../styles/organizing.css";
import CP from "../media/anuj-aggarwal.png";
import CP2 from "../media/ravi-shanker.png";
import P from "../media/raguram.png";
import P2 from "../media/priy.png";
import M from "../media/maneek.png";
import Chair from "../media/nm.png";
import CoChair from "../media/rm.png";
import Conv from "../media/jd.png";
import Conv2 from "../media/sy.png";

export default function Organizing() {
  //Chief Patrons
  const ChiefPatrons = [
    {
      name: "Shri Anuj Aggarwal",
      place: "Chairman, DIT University",
      img: CP,
    },
    {
      name: "Shri N. Ravi Shanker",
      place: "Chancellor, DIT University",
      img: CP2,
    },
  ];

  //Patrons
  const Patrons = [
    {
      name: " Prof. G. Raghurama",
      place: "Vice-Chancellor, DIT University",
      img: P,
    },
    {
      name: "Prof. Priyadarsan Patra",
      place: "Pro Vice Chancellor, DIT University",
      img: P2,
    },
  ];

  //Mentor
  const Mentor = [
    {
      name: "Prof. Maneek Kumar",
      place: "Director STEAM, DIT University",
      img: M,
    },
  ];

  const Chairperson = [
    {
      name: "Prof. Naresh M. Chadha",
      place: "Dean, Physical Sciences, DIT University",
      img: Chair,
    },
  ];
  const CoChairperson = [
    {
      name: "Prof. Rakesh Mohan",
      place: "Dean Student Welfare, DIT University",
      img: CoChair,
    },
  ];

  const Conveners = [
    {
      name: "Dr. Jogendra Kumar",
      place: "School of Physical Sciences, DIT University",
      img: Conv,
    },
  ];
  const Conveners1 = [
    {
      name: "Dr. Seema Yadav",
      place: "School of Physical Sciences, DIT University",
      img: Conv2,
    },
  ];

  const LocalOrganizingCommittee = [
    {
      name: "Dr. Pooja S. Saxena",
      place: "School of Physical Sciences, DIT University",
    },
    {
      name: "Dr. Shilpi Singhal",
      place: "School of Physical Sciences, DIT University",
    },
    {
      name: "Dr. Nitin Kumar Kamboj",
      place: "School of Physical Sciences, DIT University",
    },
    {
      name: "Dr. Dheeraj Kumar Joshi",
      place: "School of Physical Sciences, DIT University",
    },
    {
      name: "Dr. Awadhesh Kumar Pandey",
      place: "School of Physical Sciences, DIT University",
    },
    {
      name: "Dr. Biplab Dhar",
      place: "School of Physical Sciences, DIT University",
    },
    {
      name: "Dr. Ravi Shukla",
      place: "School of Physical Sciences, DIT University",
    },
    {
      name: "Dr. Manisha Duseja",
      place: "School of Physical Sciences, DIT University",
    },
    {
      name: " Dr. Jabrinder Singh",
      place: "School of Physical Sciences, DIT University",
    },
    {
      name: "Dr. Vishakha Kaushik",
      place: "School of Physical Sciences, DIT University",
    },
    {
      name: "Dr. Natasha Awasthi",
      place: "School of Physical Sciences, DIT University",
    },
    {
      name: "Dr. Ankita Khanna",
      place: "School of Physical Sciences, DIT University",
    },
  ];
  const ResearchScholarsVolunteers = [
    {
      name: "Mr. Pervinder Singh",
      place: "School of Physical Sciences, DIT University",
    },
    {
      name: "Ms. Shruti Tomar",
      place: "School of Physical Sciences, DIT University",
    },
    {
      name: "Ms. Soniya Gupta",
      place: "School of Physical Sciences, DIT University",
    },
  ];
  return (
    <main>
      <CommonPanel heading="Organizing Committee" />
      <div className="container">
        <div className="centering">
          <h2>Chief Patrons</h2>
          <div className="stacks">
            {ChiefPatrons.map((item, index) => {
              return (
                <Cards
                  key={index}
                  name={item.name}
                  place={item.place}
                  img={item.img}
                />
              );
            })}
          </div>
          <h2>Patrons</h2>
          <div className="stacks">
            {Patrons.map((item, index) => {
              return (
                <Cards
                  key={index}
                  name={item.name}
                  place={item.place}
                  img={item.img}
                />
              );
            })}
          </div>
          <h2>Mentor</h2>
          <div className=" stack2">
            {Mentor.map((item, index) => {
              return (
                <Cards
                  key={index}
                  name={item.name}
                  place={item.place}
                  img={item.img}
                />
              );
            })}
          </div>
          <h2>Chair Conference</h2>
          <div className=" stack2">
            {Chairperson.map((item, index) => {
              return (
                <Cards
                  key={index}
                  name={item.name}
                  place={item.place}
                  img={item.img}
                />
              );
            })}
          </div>
          <h2>Co-Chair Conference</h2>
          <div className=" stack2">
            {CoChairperson.map((item, index) => {
              return (
                <Cards
                  key={index}
                  name={item.name}
                  place={item.place}
                  img={item.img}
                />
              );
            })}
          </div>
          <h2>Convener</h2>
          <div className="stack2">
            {Conveners.map((item, index) => {
              return (
                <Cards
                  key={index}
                  name={item.name}
                  place={item.place}
                  img={item.img}
                />
              );
            })}
          </div>
          <h2>Co-Convener</h2>
          <div className="stack2">
            {Conveners1.map((item, index) => {
              return (
                <Cards
                  key={index}
                  name={item.name}
                  place={item.place}
                  img={item.img}
                />
              );
            })}
          </div>
        </div>
        <h2>Local Organizing Committee.:</h2>
        <div className="stack">
          {LocalOrganizingCommittee.map((item, index) => {
            return <Cards key={index} name={item.name} place={item.place} />;
          })}
        </div>
        <h2>Research Scholars Volunteers :</h2>
        <div className="stack">
          {ResearchScholarsVolunteers.map((item, index) => {
            return <Cards key={index} name={item.name} place={item.place} />;
          })}
        </div>
      </div>
    </main>
  );
}
