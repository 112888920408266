import React from "react";
import Logo from "../media/dit_white.png";
import {
  FaInstagramSquare,
  FaLinkedin,
  FaFacebookSquare,
} from "react-icons/fa";
import "../styles/footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      <div className="footer-text">
        <img src={Logo} alt="DIT University" />
        <br />
        DIT was established in 1998 as Dehradun Institute of Technology by
        Unison Group, which later was given the status of autonomous body in
        2012 by UGC. Further, in 2013 it was conferred the status of University
        under act no. 10 of 2013 by Uttarakhand State Government and recognized
        by UGC under section 2(F) of UGC act 1956, due to its excellence in
        Education and Research. The University offers various programs at
        undergraduate and postgraduate level which includes Engineering,
        Pharmacy, Architecture, Management, Science and Humanity & Liberal Arts
        Courses.
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/dituniversity/?hl=en"
            >
              <FaInstagramSquare size="40" color="#fff" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/school/dit-university/"
            >
              <FaLinkedin size="40" color="#fff" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/dituni.engg/"
            >
              <FaFacebookSquare size="40" color="#fff" />
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-links">
        <h3 className="navss">Useful Links</h3>
        <ul>
          <Link to={"/home"}>
            <li>Home</li>
          </Link>
          <Link to={"/advisory"}>
            <li>Advisory Committee</li>
          </Link>
          <Link to={"/organizing"}>
            <li>Organizing Committee</li>
          </Link>
          <Link to={"/speakers"}>
            <li>Speakers</li>
          </Link>
          <Link to={"/register"}>
            <li>Registration</li>
          </Link>
          <Link to={"/about"}>
            <li>About Us</li>
          </Link>
        </ul>
      </div>

      <div className="copyright">
        © Copyright 2022 All Rights Reserved by Naman Pokhriyal
      </div>
    </footer>
  );
}
