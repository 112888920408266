import React from "react";
import "../styles/cards.css";

export default function Cards(Params) {
  return (
    <div
      className="card"
      style={Params.img && { alignItems: "center", minWidth: "400px" }}
    >
      <img src={Params.img} alt="" />
      <p className="name ">{Params.name}</p>
      <p className="place ">{Params.place}</p>
      {Params.subtext && <p className="subtext ">{Params.subtext}</p>}
    </div>
  );
}
