import React from "react";
import CommonPanel from "../components/CommonPanel";
import "../styles/about.css";
import DIT from "../media/ditgate.jfif";
import bg from "../media/motionbg.png";

export default function About() {
  return (
    <main className="About">
      <CommonPanel heading="About Us" />
      <div className="about-uni">
        <h2>About the University</h2>
        <p className="divide">
          <p className="text">
            DIT was established in 1998 as Dehradun Institute of Technology by
            Unison Group, which later was given the status of autonomous body in
            2012 by UGC. Further, in 2013 it was conferred the status of
            University under act no. 10 of 2013 by Uttarakhand State Government
            and recognized by UGC under section 2(F) of UGC act 1956, due to its
            excellence in Education and Research. The University offers various
            programs at undergraduate and postgraduate level which includes
            Engineering, Pharmacy, Architecture, Management, Science and
            Humanity & Liberal Arts Courses.
          </p>
          <span>
            <img className="ditgate" src={DIT} />
            <img className="bg" src={bg} />
          </span>
        </p>
      </div>
      <div className="about-school">
        <h2>About Mathematics Department</h2>
        <p className="text">
          Department of Mathematics was established since the inception of
          Dehradun Institute of Technology in 1998 and was given an independent
          status in 2013 as Department of Mathematics. The Department provides
          an outstanding research environment complemented by excellent teaching
          which facilitates the students to flourish in different areas of
          academics and industry. The department, equipped with highly qualified
          faculty, offers academic programs leading to the award of Ph. D.
          degree. The thrust areas in which faculty members working are: Special
          Functions, Numerical Analysis, Algebra, Coding Theory, Thermal
          Instability in Incompressible Fluids, Fuzzy Logic & applications,
          Optimization, Fluid Dynamics, Tensor Analysis, Cosmology and
          Differential Geometry. ABOUT THE CITY DEH
        </p>
      </div>
      <div className="about-research">
        <h2>About The City Dehradun</h2>
        <p className="text">
          Nestled in the Doon Valley in the foothills of the great Himalayas and
          flanked by two very beautiful as well as mighty rivers the Ganges and
          the Yamuna on its eastern and western side, Dehradun is one of the
          most ancient cities of India. Dehradun, the capital of Uttarakhand,
          got its name by the union of two words ‘Dera’ and ‘Dun’ that means
          Camp in the Valley. Dehradun is a trendy hilly tourist destination
          with its impeccable beauty and charismatic sceneries. Along with
          tourist spots, famous scientific museum and lush green sprawling
          parks, Dehradun is adorned with several famous historic buildings that
          have great architectural value, and a number of temples that have
          spell binding beauty and at the same time are considered to be very
          auspicious. The weather in Dehradun in the month of October remains
          pleasant with temperature varying between 170C to 290C. The city also
          receives a good amount of snowfalls during winters which is a major
          attraction of tourist.
        </p>
      </div>
    </main>
  );
}
