import React from "react";
import "../styles/home.css";
import { BiChair } from "react-icons/bi";
import { MdLocationPin } from "react-icons/md";
import { BsFillPersonFill } from "react-icons/bs";

export default function Home() {
  return (
    <div>
      <section className="home-panel">
        <div className="darken">
          <h2>
            2nd International Conference on Applied Mathematics and
            Computational Sciences-2022
          </h2>
          <h3>( ICAMCS 2022 )</h3>
          <p>12th-14th, October 2022</p>
          <ul>
            <li>
              <BiChair color="#d2c72b" /> 3 Days
            </li>
            <li>
              <BsFillPersonFill color="#d2c72b" /> School of Physical Sciences
            </li>
            <li>
              <MdLocationPin color="#d2c72b" /> DIT University, Uttarakhand,
              India
            </li>
          </ul>
          <h3>Virtual Mode</h3>
        </div>
      </section>
      <section className="home-about">
        <h2>About the conference</h2>
        <p className="text">
          Mathematics is all around in the nature and it is an integral part of
          everyday life, with applications ranging from investing money,
          predicting costs, and comprehending computers to building mathematical
          models of complicated problems and solving them using computational
          tools. Many new tools and approaches have been developed over time to
          solve a wide range of real-world problems that were previously
          difficult to solve analytically. Applied Mathematics and Computational
          Sciences is one such recent highly popular and well - known
          interdisciplinary field. The two subjects have applications in every
          field of science, engineering, technology, and industry, but combining
          them yield new powerful techniques. These techniques can solve
          problems in a variety of fields, such as finance, computing,
          insurance, industries, medical, research and development, public
          service, aircraft, auto-mobiles, textiles, communication systems,
          biological-physical-mathematical & biomedical sciences,
          earth-atmosphere environmental sciences, and so on. This
          multidisciplinary field has expanded opportunities not only in
          humanities and creative arts, but in social sciences, archaeology,
          architecture, music, and the visual arts also.
        </p>
        <h3>Aim and Scope of the Conference:</h3>
        <p className="text">
          In a nutshell, applied mathematics and computational sciences play a
          critical role in a wide range of interrelated areas and hence a review
          of the various aspects of the field, recent advancements, and
          achievements is the need of hour. The purpose of this International
          Conference is to provide:
        </p>
        <ul className="default-list">
          <li>
            A global platform for research scholars, academicians, scientists,
            and industry people engaged in different fields of mathematics and
            computer science around the world, where the participants can
            interact and express their thoughts, challenges, recent developments
            and innovative ideas of this rapidly growing field and can share
            their knowledge.
          </li>
          <li>
            A stage where experts will share their research findings, allowing
            participants to gain a comprehensive understanding of this
            interdisciplinary subject through various sessions of the
            conference, which will assist, guide, and motivate them,
            particularly young research scholars, to pave their path in this
            field.
          </li>
          <li>
            An exposure to participants of different regions and foster
            interactions among them to facilitate their growth in the chosen
            research field.
          </li>
          <li>
            An interactive forum which may lead to an opportunity for new
            collaborations. The conference will feature keynote address, a
            number of plenary talks, invited lectures, contributory lectures and
            poster presentations.
          </li>
        </ul>
        <h3>About Dehradun and Tourist Attractions</h3>
        <p className="text">
          Nestled in the Doon Valley in the foothills of the great Himalayas and
          flanked by two very beautiful as well as mighty rivers the Ganges and
          the Yamuna on its eastern and western side, Dehradun is one of the
          most ancient cities of India. Dehradun, the capital of Uttarakhand,
          got its name by the union of two words ‘Dera’ and ‘Dun’ that means
          Camp in the Valley. Dehradun is a trendy hilly tourist destination
          with its impeccable beauty and charismatic sceneries. Along with
          tourist spots, famous scientific museum and lush green sprawling
          parks, Dehradun is adorned with several famous historic buildings that
          have great architectural value, and a number of temples that have
          spell binding beauty and at the same time are considered to be very
          auspicious. The weather in Dehradun in the month of October remains
          pleasant with temperature varying between 170C to 290C. The city also
          receives a good amount of snowfalls during winters which is major
          attraction of tourist.
        </p>
        <h2>Conference Theme</h2>
        <p className="text">
          ICAMCS-2022 is a specialized conference on mathematics and its real
          world applications in various branches of science.
        </p>
        <div className="tracks">
          <div>
            <h4>Track 01</h4>
            <ul className="dashed-list">
              <li>Mathematical Modelling</li>
              <li>Mathematical Biology</li>
              <li>Theory of Differential Equations</li>
              <li>Stability of Flows, Fluid Mechanics</li>
              <li>Partial Differential Equations</li>
              <li>Dynamical Systems</li>
            </ul>
          </div>
          <div>
            <h4>Track 02</h4>
            <ul className="dashed-list">
              <li>
                Numerical Solution ODEs, Numerical Solution PDEs (inc. BVPs)
              </li>
              <li>Stochastic Differential Equation</li>
              <li>Numerical Linear Algebra, Numerical Integral Equations</li>
              <li>Approximation Theory & its applications</li>
              <li>Difference Equations</li>
            </ul>
          </div>
          <div>
            <h4>Track 03</h4>
            <ul className="dashed-list">
              <li>Computational Physics</li>
              <li>Computational Mechanics</li>
              <li>Computational Chemistry</li>
              <li>Environmental Science & its applications</li>
              <li>Problems emerging from Nanotechnology</li>
            </ul>
          </div>
          <div>
            <h4>Track 04</h4>
            <ul className="dashed-list">
              <li>Machine Learning with Mathematical Approach</li>
              <li>Cryptography & Network Security</li>
              <li>Image Processing with mathematical Tools</li>
              <li>
                Decomposition and Reconstruction Algorithms, Subdivision
                Algorithms
              </li>
              <li>Big Data Analytics</li>
              <li>
                Computational Intelligence (Fuzzy Systems, Evolutionary
                Computing)
              </li>
              <li>Data Structures, Data Science</li>
              <li>Optimization</li>
              <li>Graph Theory</li>
              <li>Algorithm Design and Analysis</li>
              <li>Neural Networks, Genetic Algorithms, Game Theory</li>
              <li>Applied Discrete Mathematics</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}
