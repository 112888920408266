import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./Pages/Home";
import ImpDates from "./components/ImpDates";
import About from "./Pages/About";
import Register from "./Pages/Register";
import Speakers from "./Pages/Speakers";
import Schedule from "./Pages/Schedule";
import Advisory from "./Pages/Advisory";
import Author from "./Pages/Author";
import Abstract from "./Pages/Abstract";
import Organizing from "./Pages/Organizing";
import Contact from "./Pages/Contact";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Gallery from "./components/Gallery";

function App() {
  document.querySelector(".unique");
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="*" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/author" element={<Author />} />
          <Route exact path="/abstract" element={<Abstract />} />
          <Route exact path="/advisory" element={<Advisory />} />
          <Route exact path="/organizing" element={<Organizing />} />
          <Route exact path="/speakers" element={<Speakers />} />
          <Route exact path="/schedule" element={<Schedule />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/about" element={<About />} />
        </Routes>
        <Gallery />
        <ImpDates />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
