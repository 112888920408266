import React from "react";
import CommonPanel from "../components/CommonPanel";
import "../styles/guidlines.css";
import ABS from "../media/Abstract_template.doc";
import POS from "../media/Poster_template.ppt";

export default function Author() {
  return (
    <main>
      <CommonPanel heading="Author" />
      <div className="content">
        <ul className="author-list">
          <li>
            Abstract book of the conference would be published as a monograph
            with ISBN and DOI.
          </li>
          <li>
            After peer review, selected papers will be published in UGC-Care
            list/SCOPUS/WOS indexed Journals or indexed Conference Proceedings.
          </li>
          <li>
            To publish the papers from other streams, we are in conversation
            with publishers. The participants will be updated for the same.
          </li>
        </ul>
        <div className="author-text">
          Download the Extended Abstract Template
          <a href={ABS} target="_blank" rel="noreferrer" download>
            <button className="author-btn">Download</button>
          </a>
        </div>
        <div className="author-text">
          Download the Poster Template
          <a href={POS} target="_blank" rel="noreferrer" download>
            <button className="author-btn">Download</button>
          </a>
        </div>
      </div>
    </main>
  );
}
