import React from "react";
import CommonPanel from "../components/CommonPanel";
import "../styles/guidlines.css";

export default function Abstract() {
  return (
    <main>
      <CommonPanel heading="Extended Abstract/Poster Submission" />

      <div className="content">
        <div className="author-text">
          Submission of extended abstract through Easychair
          <a
            href="https://easychair.org/conferences/?conf=icamcs2022"
            target="_blank"
            rel="noreferrer"
          >
            <button className="author-btn">Click Here</button>
          </a>
        </div>
        <div className="author-text">
          Submission of posters using the Google form
          <a href=" https://bit.ly/3NPoster" target="_blank" rel="noreferrer">
            <button className="author-btn">Click Here</button>
          </a>
        </div>
      </div>
    </main>
  );
}
