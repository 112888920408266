import React from "react";
import CommonPanel from "../components/CommonPanel";

export default function Schedule() {
  return (
    <main>
      <CommonPanel heading="Schedule" />
    </main>
  );
}
