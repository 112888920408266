import React from "react";
import CommonPanel from "../components/CommonPanel";
import "../styles/register.css";
import RegisPic from "../media/register1.jpg";
import Bullet from "../media/bullet.png";

export default function Register() {
  return (
    <main>
      <CommonPanel heading="Registration" />
      <div className="options">
        <h2>Registration</h2>
        <div className="registerCards">
          <div className="registerCard">
            <div className="box">
              <img src={RegisPic} alt="" />
            </div>
            <p className="title">Indian National</p>
            <p className="amount">₹250/-</p>
            <p className="text sub">
              <img src={Bullet} alt="" />
              Only Participants
            </p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdbzguvBY3YHGTWmBnNCzDDoANA0kVEt5UzgclRyJXyt45bvw/viewform"
              target="_blank"
              rel="noreferrer"
            >
              <button>Register</button>
            </a>
          </div>
          <div className="registerCard">
            <div className="box">
              <img src={RegisPic} alt="" />
            </div>
            <p className="title">Research Scholar (Indian National)</p>
            <p className="amount">₹500/-</p>
            <p className="text sub">
              <img src={Bullet} alt="" /> Abstract/Paper Presentation
            </p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdbzguvBY3YHGTWmBnNCzDDoANA0kVEt5UzgclRyJXyt45bvw/viewform"
              target="_blank"
              rel="noreferrer"
            >
              <button>Register</button>
            </a>
          </div>
          <div className="registerCard">
            <div className="box">
              <img src={RegisPic} alt="" />
            </div>
            <p className="title">Faculty/Industry Person (Indian National)</p>
            <p className="amount">₹1000/-</p>
            <p className="text sub">
              <img src={Bullet} alt="" /> Abstract/Paper Presentation
            </p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdbzguvBY3YHGTWmBnNCzDDoANA0kVEt5UzgclRyJXyt45bvw/viewform"
              target="_blank"
              rel="noreferrer"
            >
              <button>Register</button>
            </a>
          </div>
        </div>
        <div className="registerCards">
          <div className="registerCard">
            <div className="box">
              <img src={RegisPic} alt="" />
            </div>
            <p className="title">Foriegn National</p>
            <p className="amount">$10</p>
            <p className="text sub">
              <img src={Bullet} alt="" /> Only Participants
            </p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdbzguvBY3YHGTWmBnNCzDDoANA0kVEt5UzgclRyJXyt45bvw/viewform"
              target="_blank"
              rel="noreferrer"
            >
              <button>Register</button>
            </a>
          </div>
          <div className="registerCard">
            <div className="box">
              <img src={RegisPic} alt="" />
            </div>
            <p className="title">Research Scholar (Foriegn National)</p>
            <p className="amount">$20</p>
            <p className="text sub">
              <img src={Bullet} alt="" /> Abstract/Paper Presentation
            </p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdbzguvBY3YHGTWmBnNCzDDoANA0kVEt5UzgclRyJXyt45bvw/viewform"
              target="_blank"
              rel="noreferrer"
            >
              <button>Register</button>
            </a>
          </div>
          <div className="registerCard">
            <div className="box">
              <img src={RegisPic} alt="" />
            </div>
            <p className="title">Faculty/Industry Person (Foriegn National)</p>
            <p className="amount">$40</p>
            <p className="text sub">
              <img src={Bullet} alt="" /> Abstract/Paper Presentation
            </p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdbzguvBY3YHGTWmBnNCzDDoANA0kVEt5UzgclRyJXyt45bvw/viewform"
              target="_blank"
              rel="noreferrer"
            >
              <button>Register</button>
            </a>
          </div>
        </div>
        <ul className="charges">
          <p className="title">
            * Peer-reviewed articles will be published in SCOPUS indexed
            journals or UGC-Care list journals, and participants will be asked
            to pay an article processing charge (APC)
          </p>
        </ul>
      </div>
    </main>
  );
}
