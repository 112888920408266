import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/navbar.css";
import Logo from "../media/dit_white.png";
import PDF from "../media/ICAMCS-2022_Booklet.pdf";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";

export default function Navbar() {
  document.addEventListener("scroll", function (e) {
    if (window.scrollY > 0) document.querySelector("nav").classList.add("dark");
    else document.querySelector("nav").classList.remove("dark");
  });
  const [tog, settog] = useState(false);
  const toggleMenu = () => {
    document.querySelector(".right-nav").classList.toggle("slide");
    settog(!tog);
  };

  const SkipTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.document.console.log("Ok");
  };

  return (
    <nav className="">
      <div className="left-nav">
        <img src={Logo} alt="DIT" />
      </div>
      <div className="right-nav slide">
        <ul className="nav-list">
          <Link to={"/home"}>
            <li className="nav-link" onClick={SkipTop}>
              Home
            </li>
          </Link>
          <Link to={"/speakers"}>
            <li className="nav-link" onClick={SkipTop}>
              Speaker
            </li>
          </Link>
          <li className="nav-link" onClick={SkipTop}>
            Committee
            <ul className="pop-menu">
              <Link to={"/advisory"}>
                <li>Advisory</li>
              </Link>
              <Link to={"/organizing"}>
                <li>Organizing</li>
              </Link>
            </ul>
          </li>
          <li className="nav-link" onClick={SkipTop}>
            Submissions
            <ul className="pop-menu">
              <Link to={"/author"}>
                <li>Author Guidlines</li>
              </Link>
              <Link to={"/abstract"}>
                <li>Extended Abstract/Poster Submission</li>
              </Link>
            </ul>
          </li>
          <Link to={"/register"}>
            <li className="nav-link" onClick={SkipTop}>
              Registration
            </li>
          </Link>
          <a href={PDF} target="_blank" rel="noreferrer" download>
            <li className="nav-link" onClick={SkipTop}>
              Download
            </li>
          </a>
          <Link to={"/about"}>
            <li className="nav-link" onClick={SkipTop}>
              About Us
            </li>
          </Link>
          <Link to={"/contact"}>
            <li className="nav-link" onClick={SkipTop}>
              Contact Us
            </li>
          </Link>
        </ul>
      </div>
      <button className="toggle" onClick={toggleMenu}>
        {tog ? <MdClose /> : <GiHamburgerMenu />}
      </button>
    </nav>
  );
}
